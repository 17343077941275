import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import styles from './SegmentLeads.module.css';
import Title from '../../../../elements/Title';
import Text from '../../../../elements/Text';
import Image from '../../../../elements/Image';

const SegmentLeads = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/people/segment-leads.md/" } }) {
        edges {
          node {
            frontmatter {
              title
              body
            }
          }
        }
      }
    }
  `);

  const { title, body } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={`${styles.segmentContainer}`}>
      <div className="col-md-6 px-0">
        <Image name="people_segment" className={styles.imageBig}/>
      </div>
      <div className={`${styles.textContainer} col-md-6 px-0`}>
        <Title className={styles.title}>{title}</Title>
        <Text>{body}</Text>
      </div>
    </div>
  );
};

export default SegmentLeads;
