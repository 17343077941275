import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import styles from './RobustTracking.module.css';
import Title from '../../../../elements/Title';
import Text from '../../../../elements/Text';
import Image from '../../../../elements/Image';

const RobustTracking = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/people/robust-tracking.md/" } }) {
        edges {
          node {
            frontmatter {
              title
              body
              litem {
                item
              }
            }
          }
        }
      }
    }
  `);

  const { title, body, litem } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={`${styles.robustContainer}`}>
      <div className={styles.titleWrapper}>
        <Title className={styles.mainTitle}>{title}</Title>
      </div>
      <div className={`${styles.innerContainer} d-flex justify-content-center`}>
        <Text className={`${styles.text} col-md-6 px-0`}>
          {body}
          <ul className={`${styles.manageCampaignSlide__list}`}>
            {litem.map((listing, index) => (
              <li key={index}>
                <span>{listing.item}</span>
              </li>
            ))}
          </ul>
        </Text>
        <div className="col-md-6 px-0">
          <Image name="robust_tracking" className={styles.imageBig}/>
        </div>
      </div>
    </div>
  );
};

export default RobustTracking;
