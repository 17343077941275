import React from 'react';

import Layout from '../../components/blocks/Layout';
import SEO from '../../components/blocks/SEO';
import PersonalizedOffer from '../../components/slides/features/people/PersonalizedOffer';
import RobustTracking from '../../components/slides/features/people/RobustTracking';
import SameOffer from '../../components/slides/features/people/SameOffer';
import SegmentLeads from '../../components/slides/features/people/SegmentLeads';

const Features = () => (
  <Layout>
    <SEO
      title="People | Deadline Funnel"
      description="Create a personal journey for each of your subscribers, and present unique offers to each person through your emails and landing pages."
    />
    <PersonalizedOffer />
    <SegmentLeads />
    <RobustTracking />
    <SameOffer />
  </Layout>
);

export default Features;