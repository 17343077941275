import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import styles from './PersonalizedOffer.module.css';
import Title from '../../../../elements/Title';
import Text from '../../../../elements/Text';
import Image from '../../../../elements/Image';
import Breadcrumbs from '../../../../blocks/Breadcrumbs';

const PersonalizedOffer = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/people/personalized-offer.md/" } }) {
        edges {
          node {
            frontmatter {
              title
              body
            }
          }
        }
      }
    }
  `);

  const { title, body } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={`${styles.offerContainer} d-flex justify-content-center align-items-center`}>
      <div className={`${styles.offerText} col-md-6 px-0`}>
        <Breadcrumbs crumbs={['<a href="/features">Features</a>', 'People']} />
        <Title className={styles.mainTitle}>{title}</Title>
        <Text className={styles.textBlock}>
          <div dangerouslySetInnerHTML={{ __html: body }}></div>
        </Text>
      </div>

      <div className="col-md-6 px-0">
        <Image name="people_offer" className={styles.imageDiv}/>
      </div>
    </div>
  );
};

export default PersonalizedOffer;
