import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import styles from './SameOffer.module.css';
import Title from '../../../../elements/Title';
import Text from '../../../../elements/Text';
import Image from '../../../../elements/Image';

const SameOffer = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/people/same-offer.md/" } }) {
        edges {
          node {
            frontmatter {
              title
              body
            }
          }
        }
      }
    }
  `);

  const { title, body } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={`${styles.sameContainer} d-flex justify-content-center align-items-center`}>
      <Title className={styles.titleMobile}>{title}</Title>
      <div className="col-md-6 px-0">
          <Image name="same_offer" className={styles.imageBig}/>
        </div>
      <div className={`${styles.textWrapper} col-md-6 px-0`}>
        <Title className={styles.title}>{title}</Title>
        <Text className={styles.text}>{body}</Text>
      </div>
    </div>
  );
};

export default SameOffer;
